exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artwork-tsx": () => import("./../../../src/pages/artwork.tsx" /* webpackChunkName: "component---src-pages-artwork-tsx" */),
  "component---src-pages-contactme-tsx": () => import("./../../../src/pages/contactme.tsx" /* webpackChunkName: "component---src-pages-contactme-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-weddings-tsx": () => import("./../../../src/pages/weddings.tsx" /* webpackChunkName: "component---src-pages-weddings-tsx" */),
  "component---src-pages-world-tsx": () => import("./../../../src/pages/world.tsx" /* webpackChunkName: "component---src-pages-world-tsx" */)
}

